
// // Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
// @import '~@angular/material/theming';
// // Plus imports for other components in your app.

// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// @include mat-core();

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $AnbarStoreWeb-primary: mat-palette($mat-indigo);
// $AnbarStoreWeb-accent: mat-palette($mat-pink, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $AnbarStoreWeb-warn: mat-palette($mat-red);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $AnbarStoreWeb-theme: mat-light-theme((
//   color: (
//     primary: $AnbarStoreWeb-primary,
//     accent: $AnbarStoreWeb-accent,
//     warn: $AnbarStoreWeb-warn,
//   )
// ));

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include angular-material-theme($AnbarStoreWeb-theme);

// /* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.image-right{
  position: absolute;
  right:0px;
  top:0px;

}
.image-cover-section{

}


/* Add application styles & imports to this file! */

* {box-sizing: border-box;}

.img-zoom-container {
  position: relative;
}

.img-zoom-lens {
  position: absolute;
  border: 1px solid #d4d4d4;
  /*set the size of the lens:*/
  width: 40px;
  height: 40px;
}

.img-zoom-result {
  border: 1px solid #d4d4d4;
  /*set the size of the result div:*/
  width: 300px;
  height: 300px;
}

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
